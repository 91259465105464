<template>
  <div class="animate-pulse p-4">
    <div class="bg-wrh-gray-light w-1/4 flex items-center h-6 mb-5">
    </div>
    <div
      class="
        mdlg:h-[calc(100vh-266px)]
        grid grid-cols-1
        mdlg:grid-cols-[1fr,330px]
        gap-6
      "
    >
      <div class="bg-wrh-gray-light w-full flex items-center justify-center">
        <span class="bg-wrh-gray-extralight w-[calc(100%-32px)] h-full"></span>
      </div>
      <div class="bg-wrh-gray-light w-3/4 flex flex-col p-4">
        <span class="bg-wrh-gray-extralight w-[calc(100%-32px)] h-4 mb-2"></span>
        <span class="bg-wrh-gray-extralight w-[calc(100%-32px)] h-2 mb-1"></span>
        <span class="bg-wrh-gray-extralight w-[calc(100%-32px)] h-2 mb-4"></span>
        <span class="bg-wrh-gray-extralight w-30 h-8"></span>
      </div>
    </div>
  </div>
</template>
