<template>
    <ul class="animate-pulse">
        <li class="grid grid-cols-[minmax(110px,1fr),minmax(110px,1fr),minmax(50px,80px),minmax(110px,1fr),20px] lg:grid-cols-[minmax(160px,200px),210px,150px,150px,1fr] md:grid-cols-[minmax(110px,1fr),minmax(110px,1fr),60px,minmax(100px,1fr),70px] gap-x-2 md:gap-2 border-wrh-blue-background border-b-1 md:mx-6 item-center mx-4"
        >
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4">
                <span class="bg-wrh-gray-extralight w-6 h-2"></span>
                <span class="bg-wrh-gray-extralight w-6 h-2 ml-2"></span>
            </div>
        </li>
         <li class="grid grid-cols-[minmax(110px,1fr),minmax(110px,1fr),minmax(50px,80px),minmax(110px,1fr),20px] lg:grid-cols-[minmax(160px,200px),210px,150px,150px,1fr] md:grid-cols-[minmax(110px,1fr),minmax(110px,1fr),60px,minmax(100px,1fr),70px] gap-x-2 md:gap-2 border-wrh-blue-background border-b-1 md:mx-6 item-center mx-4"
        >
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4">
                <span class="bg-wrh-gray-extralight w-6 h-2"></span>
                <span class="bg-wrh-gray-extralight w-6 h-2 ml-2"></span>
            </div>
        </li>
         <li class="grid grid-cols-[minmax(110px,1fr),minmax(110px,1fr),minmax(50px,80px),minmax(110px,1fr),20px] lg:grid-cols-[minmax(160px,200px),210px,150px,150px,1fr] md:grid-cols-[minmax(110px,1fr),minmax(110px,1fr),60px,minmax(100px,1fr),70px] gap-x-2 md:gap-2 border-wrh-blue-background border-b-1 md:mx-6 item-center mx-4"
        >
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4">
                <span class="bg-wrh-gray-extralight w-6 h-2"></span>
                <span class="bg-wrh-gray-extralight w-6 h-2 ml-2"></span>
            </div>
        </li>
         <li class="grid grid-cols-[minmax(110px,1fr),minmax(110px,1fr),minmax(50px,80px),minmax(110px,1fr),20px] lg:grid-cols-[minmax(160px,200px),210px,150px,150px,1fr] md:grid-cols-[minmax(110px,1fr),minmax(110px,1fr),60px,minmax(100px,1fr),70px] gap-x-2 md:gap-2 border-wrh-blue-background border-b-1 md:mx-6 item-center mx-4"
        >
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4"><span class="bg-wrh-gray-extralight w-full mdlg:w-[calc(100%-32px)] h-3"></span></div>
            <div class="w-3/4 flex items-center py-4">
                <span class="bg-wrh-gray-extralight w-6 h-2"></span>
                <span class="bg-wrh-gray-extralight w-6 h-2 ml-2"></span>
            </div>
        </li>
    </ul>
</template>
